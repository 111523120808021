import { computed } from "vue";

export function useConditions() {
  const { $pinia } = useNuxtApp();

  const discountStore = useDiscountStore($pinia);
  const onboardingStore = useOnboardingStore($pinia);

  const hasFreeTrial = computed<boolean>(() => useCheckout().trialDays.value > 0);
  const hasDiscountCode = computed<boolean>(() => discountStore.code !== "");
  const hasSkippedFunnel = computed<boolean>(() => onboardingStore.skippedOnboarding);
  const hasGoalMaintain = computed<boolean>(() => onboardingStore.registrationParams.goal === "maintain");
  const hasGoalLose = computed<boolean>(() => onboardingStore.registrationParams.goal === "lose");
  const hasGoalGain = computed<boolean>(() => onboardingStore.registrationParams.goal === "gain");

  const choosePlanTrial = computed<boolean>(() => hasFreeTrial.value && !hasDiscountCode.value);

  return {
    hasFreeTrial,
    hasDiscountCode,
    hasSkippedFunnel,
    hasGoalMaintain,
    hasGoalLose,
    hasGoalGain,
    choosePlanTrial,
  };
}
