<script lang="ts" setup>
import AppWeight from "~/components/app/AppWeight.vue";
import OnboardingStickyButtonWrapper from "~/components/onboarding/OnboardingStickyButtonWrapper.vue";
import type { StaticScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams, UnitMass } from "~/types/onboarding/registrationParams";
import { getOnboardingConditions } from "~/utils/onboardingConditions";

const { defaultUnitMass } = useNuxtApp().$i18n;

interface Props {
  screen: StaticScreen;
  registrationParams: RegistrationParams;
}

const props = defineProps<Props>();

interface Emits {
  (
    e: "next",
    value: {
      params: {
        startWeight: number;
        unitMass: UnitMass;
      };
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();

const { getOrGetConditionalValue } = getOnboardingConditions(props);

const { kg, kgAsString, lbAsString, unitMass, validate, kgInvalid, lbInvalid, error, canContinue } = useWeight(
  props.registrationParams.startWeight || null,
  props.registrationParams.unitMass || defaultUnitMass.value,
);

function next() {
  const hasError = validate();
  if (hasError) return;

  emit("next", {
    params: {
      startWeight: kg.value as number,
      unitMass: unitMass.value,
    },
    nextScreenId: getOrGetConditionalValue(props.screen.nextStep),
  });
}
</script>

<template>
  <OnboardingStickyButtonWrapper
    :button-text="$t('registration.general.next')"
    :button-disabled="!canContinue"
    with-mobile-fallback-button
    @button-click="next"
  >
    <h1>
      {{ $t("registration.current_weight.headline") }}
    </h1>
    <div class="mb-12">
      {{ $t("registration.current_weight.teaser") }}
    </div>
    <AppWeight
      v-model:kg="kgAsString"
      v-model:lb="lbAsString"
      v-model:unitMass="unitMass"
      :kg-invalid="kgInvalid"
      :lb-invalid="lbInvalid"
      :error-message="error"
      @submit="next"
    />
  </OnboardingStickyButtonWrapper>
</template>
