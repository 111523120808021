import { FetchError } from "ofetch";

export default defineNuxtRouteMiddleware(async () => {
  const { $pinia } = useNuxtApp();
  const voucherStore = useVoucherStore($pinia);
  const userStore = useUserStore($pinia);

  if (voucherStore.temporaryVoucherCode && userStore.user) {
    try {
      await voucherStore.redeemCouponCode(voucherStore.temporaryVoucherCode);
    } catch (e) {
      if (e instanceof FetchError && e.status === 401) {
        return navigateTo("/account/login?message=redeem");
      }
    }

    voucherStore.temporaryVoucherCode = undefined;
    voucherStore.$persist();

    return navigateTo("/onboarding/success");
  }
});
