const KG_IN_POUNDS = 2.20462;

export function feetAndInchesToCm(feet: number, inches: number) {
  return Math.round((feet * 12 + inches) * 2.54);
}

export function cmToFeetAndInches(value: number) {
  const totalInches = value / 2.54;
  let feet = Math.floor(totalInches / 12);
  let inches = Math.round(totalInches % 12);

  if (inches === 12) {
    feet += 1;
    inches = 0;
  }

  return { feet, inches };
}

export function kgToPounds(value: number) {
  return Math.round(value * KG_IN_POUNDS * 10) / 10;
}

export function poundsToKg(pounds: number) {
  return Math.round((pounds / KG_IN_POUNDS) * 10000) / 10000;
}
