import dayjs from "dayjs";
import duration from "dayjs/plugin/duration.js";

// Importing extend like eslint suggests causes errors in the build process
// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(duration);

export function getMonthsFromPeriod(period: string) {
  return dayjs.duration(period).asMonths();
}
