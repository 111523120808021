export function calculateBMI(weightInKg: number, heightInCm: number) {
  const heightInMeters = heightInCm / 100;
  const bmi = weightInKg / (heightInMeters * heightInMeters);
  return parseFloat(bmi.toFixed(2));
}

export function calculateWeightForBmiAndHeight(bmi: number, heightInCm: number) {
  const heightInMeters = heightInCm / 100;
  const weight = bmi * heightInMeters * heightInMeters;
  return parseFloat(weight.toFixed(1));
}
