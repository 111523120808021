import { ref } from "vue";
import { defineStore } from "pinia";
import { DaysToSeconds } from "@ilihub/time";
import { match } from "ts-pattern";

import type { QueryParameters } from "~/utils/queryParams";

type FunnelVariant = "default" | "alternative" | "knet-calculator";

export const useFunnelStore = defineStore(
  "funnel",
  () => {
    const variant = ref<FunnelVariant>("default");

    function $reset() {
      variant.value = "default";
    }

    function isAlternativeVariant() {
      return variant.value === "alternative";
    }

    function handleQueryParameters(query: QueryParameters) {
      if (query.isNewFlow() || query.sessionShouldReset()) {
        $reset();
      }

      if (query.has("funnel_variant")) {
        match(query.getAndRemove("funnel_variant"))
          .with("default", () => {
            variant.value = "default";
          })
          .with("knet-calculator", () => {
            variant.value = "knet-calculator";
          })
          .otherwise(() => {
            variant.value = "alternative";
          });
      }
    }

    return {
      variant,
      isAlternativeVariant,
      handleQueryParameters,
      $reset,
    };
  },
  {
    persist: {
      storage: piniaPluginPersistedstate.cookies({
        maxAge: DaysToSeconds(30),
      }),
    },
  },
);
