import { ref } from "vue";
import { HoursToSeconds } from "@ilihub/time";
import { defineStore } from "pinia";
import type { ActionEventParams, ImpressionEventParams } from "~/types/biTracking";
import appConfig from "~/app.config";

export type PurchaseEvent = {
  transactionId: string;
  period: number;
  currencyCode: string;
  totalAmount: number;
  discountCode?: string;
  sku?: string;
  gateway: string;
  email: string;
  trial_days: number;
};

export type AddToCartEvent = {
  period: string;
  sku: string;
  currencyCode: string;
  price: number;
  discountCode?: string;
};

export interface ImpressionEvent extends ImpressionEventParams {}

export interface ActionEvent extends ActionEventParams {}

export const useTrackingStore = defineStore(
  "tracking",
  () => {
    const purchaseEvents = ref<PurchaseEvent[]>([]);
    const impressionEvents = ref<ImpressionEvent[]>([]);
    const actionEvents = ref<ActionEvent[]>([]);

    function addPurchaseEvent(event: PurchaseEvent) {
      purchaseEvents.value.push(event);
    }

    function addImpressionEvent(event: ImpressionEvent) {
      impressionEvents.value.push(event);
    }

    function addActionEvent(event: ActionEvent) {
      actionEvents.value.push(event);
    }

    function hasPurchaseEvents() {
      return purchaseEvents.value.length > 0;
    }

    function hasImpressionEvents() {
      return impressionEvents.value.length > 0;
    }
    function hasActionEvents() {
      return actionEvents.value.length > 0;
    }

    function clearImpressionEvents() {
      impressionEvents.value = [];
    }

    function clearPurchaseEvents() {
      purchaseEvents.value = [];
    }

    function clearActionEvents() {
      actionEvents.value = [];
    }

    return {
      purchaseEvents,
      hasPurchaseEvents,
      clearPurchaseEvents,
      addPurchaseEvent,
      impressionEvents,
      hasImpressionEvents,
      clearImpressionEvents,
      addImpressionEvent,
      actionEvents,
      hasActionEvents,
      clearActionEvents,
      addActionEvent,
    };
  },
  {
    persist: {
      pick: ["purchaseEvents", "impressionEvents"],
      storage: piniaPluginPersistedstate.cookies({
        maxAge: HoursToSeconds(appConfig.cookieMaxAgeH),
      }),
    },
  },
);
