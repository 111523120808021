<script lang="ts" setup>
import OnboardingStickyButtonWrapper from "~/components/onboarding/OnboardingStickyButtonWrapper.vue";
import type { BaseScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";
import AppImageStack from "~/components/app/AppImageStack.vue";
import { getOnboardingConditions } from "~/utils/onboardingConditions";

interface Props {
  screen: BaseScreen;
  registrationParams: RegistrationParams;
}

const props = defineProps<Props>();
const { getOrGetConditionalValue } = getOnboardingConditions(props);

interface Emits {
  (
    e: "next",
    value: {
      params: Partial<RegistrationParams>;
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();

const { t } = useNuxtApp().$i18n;

type DietKey = "no_preference" | "vegan" | "vegetarian" | "pescatarian";
const diet = computed<DietKey>(() => props.registrationParams.diet || "no_preference");

const title = computed<string>(() =>
  t(
    {
      no_preference: "onboarding.encouraging_flow.diet.delicious_recipes_affirmation.title",
      vegan: "onboarding.encouraging_flow.diet.vegan_recipes_affirmation.title",
      vegetarian: "onboarding.encouraging_flow.diet.vegetariean_recipes_affirmation.title",
      pescatarian: "onboarding.encouraging_flow.diet.pescatarian_recipes_affirmation.title",
    }[diet.value],
  ),
);

const description = computed<string>(() =>
  t(
    {
      no_preference: "onboarding.encouraging_flow.diet.delicious_recipes_affirmation.description",
      vegan: "onboarding.encouraging_flow.diet.vegan_recipes_affirmation.description",
      vegetarian: "onboarding.encouraging_flow.diet.vegetariean_recipes_affirmation.description",
      pescatarian: "onboarding.encouraging_flow.diet.pescatarian_recipes_affirmation.description",
    }[diet.value],
  ),
);

const images = computed<string[]>(
  () =>
    ({
      no_preference: [
        "process/plain/app/recipe/yazio/recipe-235.jpg",
        "process/plain/app/recipe/yazio/recipe-153.jpg",
        "process/plain/app/recipe/yazio/recipe-982.jpg",
      ],
      vegan: [
        "process/plain/app/recipe/yazio/recipe-453.jpg",
        "process/plain/app/recipe/yazio/recipe-533.jpg",
        "process/plain/app/recipe/yazio/recipe-982.jpg",
      ],
      vegetarian: [
        "process/plain/app/recipe/yazio/recipe-641.jpg",
        "process/plain/app/recipe/yazio/recipe-453.jpg",
        "process/plain/app/recipe/yazio/recipe-982.jpg",
      ],
      pescatarian: [
        "process/plain/app/recipe/yazio/recipe-153.jpg",
        "process/plain/app/recipe/yazio/recipe-641.jpg",
        "process/plain/app/recipe/yazio/recipe-982.jpg",
      ],
    })[diet.value],
);

function next() {
  emit("next", {
    params: {},
    nextScreenId: getOrGetConditionalValue(props.screen.nextStep),
  });
}
</script>

<template>
  <OnboardingStickyButtonWrapper :button-text="$t('system.general.button.i_cant_wait')" @button-click="next">
    <div class="absolute inset-x-0 top-0 z-[-1] h-[40vh] w-full bg-[url('~/assets/images/background_shape_10.svg')]" />
    <div class="container px-bs-container pt-4">
      <AppImageStack
        :images="images"
        class="mx-auto block"
        :alt="title"
        :src-image-width="800"
        :stack-max-width="540"
        aspect="small"
      />
      <div class="mx-auto flex flex-wrap justify-center lg:w-7/12">
        <h1>
          {{ title }}
        </h1>
        <div class="mb-5">{{ description }}</div>
      </div>
    </div>
  </OnboardingStickyButtonWrapper>
</template>
