<script lang="ts" setup>
import { ref } from "vue";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import AppIcon from "~/components/app/AppIcon.vue";
import OnboardingBtn from "~/components/onboarding/OnboardingBtn.vue";
import type { BaseScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams, Sex } from "~/types/onboarding/registrationParams";
import { getOnboardingConditions } from "~/utils/onboardingConditions";

interface Props {
  screen: BaseScreen;
  registrationParams: RegistrationParams;
}

const props = defineProps<Props>();
const useTransparentBackground = props.screen.config?.useTransparentBackground;

const isModalShown = ref(false);
const showDescription = ref(!useTransparentBackground);
const showHelpLink = ref(!useTransparentBackground);

const { getConditionalValue } = getOnboardingConditions(props);

function getNextScreenId() {
  if (typeof props.screen.nextStep === "string") {
    return props.screen.nextStep;
  }

  return getConditionalValue(props.screen.nextStep);
}

interface Emits {
  (
    e: "next",
    value: {
      params: { sex: Sex };
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();

function selectOption(sex: Sex) {
  emit("next", {
    params: { sex },
    nextScreenId: getNextScreenId(),
  });
}
</script>

<template>
  <div class="flex flex-col justify-between">
    <div :class="{ 'mt-12': useTransparentBackground }">
      <h1 :class="{ 'text-center text-white': useTransparentBackground }">
        {{ $t("registration.gender.headline") }}
      </h1>
      <p v-if="showDescription" class="mb-12">
        {{ $t("registration.gender.info.teaser") }}
      </p>
      <div class="flex gap-4">
        <div class="flex-1">
          <OnboardingBtn
            emoji="🙋‍♀️"
            is-column
            translation-key="registration.gender.label.female"
            @click="selectOption('female')"
          />
        </div>
        <div class="flex-1">
          <OnboardingBtn
            emoji="🙋‍♂️"
            is-column
            translation-key="registration.gender.label.male"
            @click="selectOption('male')"
          />
        </div>
      </div>
    </div>
    <div v-if="showHelpLink" class="text-center">
      <client-only>
        <button class="p-3 font-medium text-yz-blue-600" type="button" @click="isModalShown = !isModalShown">
          <AppIcon icon="mdi:information" />
          {{ $t("registration.gender.info.modal.headline") }}
        </button>

        <TransitionRoot appear :show="isModalShown" as="template">
          <Dialog as="div" class="fixed right-0 top-0" @close="isModalShown = false">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="fixed inset-0 bg-black/25" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
              <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center">
                <TransitionChild
                  as="template"
                  enter="duration-300 ease-out"
                  enter-from="opacity-0 scale-95"
                  enter-to="opacity-100 scale-100"
                  leave="duration-200 ease-in"
                  leave-from="opacity-100 scale-100"
                  leave-to="opacity-0 scale-95"
                >
                  <DialogPanel
                    class="w-full max-w-md overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                  >
                    <div class="text-right">
                      <AppIcon
                        class="cursor-pointer text-neutral-500"
                        icon="mdi:close-circle"
                        width="2rem"
                        @click="isModalShown = false"
                      />
                    </div>
                    <DialogTitle class="mb-2 w-full text-center text-2xl font-medium leading-6 text-gray-900">
                      {{ $t("registration.gender.info.modal.headline") }}
                    </DialogTitle>

                    <div class="mt-8">
                      <p class="text-center text-gray-500">
                        {{ $t("registration.gender.info.modal.description") }}
                      </p>
                    </div>

                    <div class="mt-8 flex justify-end gap-2">
                      <button
                        class="rounded-full bg-yz-blue-600 px-5 py-4 text-white shadow-md hover:bg-yz-blue-500"
                        @click="isModalShown = false"
                      >
                        {{ $t("system.general.button.got_it") }}
                      </button>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </TransitionRoot>
      </client-only>
    </div>
  </div>
</template>
