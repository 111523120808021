import { ref } from "vue";
import { defineStore } from "pinia";
import { YearsToSeconds } from "@ilihub/time";

import type { QueryParameters } from "~/utils/queryParams";

export const useIterableStore = defineStore(
  "iterable",
  () => {
    const campaignId = ref<string>("");
    const fullCampaignId = ref<string>("");
    const templateId = ref<string>("");
    const source = ref<string>("web");

    function isIterableTrackingActive() {
      return source.value === "Iterable";
    }

    function $reset() {
      campaignId.value = "";
      fullCampaignId.value = "";
      templateId.value = "";
      source.value = "web";
    }

    function handleQueryParameters(queryParameters: QueryParameters) {
      if (queryParameters.sessionShouldReset()) {
        $reset();
      }

      campaignId.value = queryParameters.getAndRemove("campaignID") ?? campaignId.value;
      fullCampaignId.value = queryParameters.getAndRemove("utm_campaign") ?? fullCampaignId.value;
      templateId.value = queryParameters.getAndRemove("utm_template") ?? templateId.value;
      source.value = queryParameters.getAndRemove("utm_source") ?? source.value;
    }

    return {
      campaignId,
      fullCampaignId,
      templateId,
      source,
      isIterableTrackingActive,
      handleQueryParameters,
      $reset,
    };
  },
  {
    persist: {
      storage: piniaPluginPersistedstate.cookies({
        maxAge: YearsToSeconds(1),
        httpOnly: true,
      }),
    },
  },
);
