import { defineStore } from "pinia";
import { DaysToSeconds } from "@ilihub/time";
import { z } from "zod";
import type { GoalTypeAbbr } from "~/types/onboarding/onboardingGoal";
import { type UnitMass, UnitMassSchema } from "~/types/onboarding/registrationParams";
import type { QueryParameters } from "~/utils/queryParams";

export const Calculator = z.enum(["bmi", "dailycalories", "idealweight"]);
export type CalculatorType = z.infer<typeof Calculator>;

export const BmiCategory = z.enum(["underweight", "healthy", "overweight", "obese", "morbidly_obese"]);
export type BmiCategoryType = z.infer<typeof BmiCategory>;

export const useCalculatorFunnelStore = defineStore(
  "calculatorFunnel",
  () => {
    const bmi = ref<{ value: number; category: BmiCategoryType; idealMin: number; idealMax: number; unit: UnitMass }>();
    const calories = ref<{ min: number; max: number }>();
    const idealWeight = ref<{ min: number; max: number; unit: UnitMass }>();
    const usedCalculator = ref<CalculatorType>();
    const { getNumberFormatted } = useLocale();

    function $reset() {
      bmi.value = undefined;
      calories.value = undefined;
      idealWeight.value = undefined;
      usedCalculator.value = undefined;
    }

    function getRecommendedGoal(): GoalTypeAbbr | undefined {
      if (usedCalculator.value === "bmi" && bmi.value) {
        if (bmi.value.category === "underweight") {
          return "gain";
        }

        if (bmi.value.category === "healthy") {
          return "maintain";
        }

        return "lose";
      }
    }

    function getCalculatorData():
      | {
          type: "bmi";
          value: string;
          category: BmiCategoryType;
          idealMin: string;
          idealMax: string;
          weightUnit: UnitMass;
        }
      | { type: "dailycalories"; min: string; max: string }
      | { type: "idealweight"; min: string; max: string; weightUnit: UnitMass }
      | undefined {
      if (usedCalculator.value === "bmi" && bmi.value) {
        return {
          type: usedCalculator.value,
          value: getNumberFormatted(bmi.value.value, { maximumFractionDigits: 1, minimumFractionDigits: 1 }),
          category: bmi.value.category,
          idealMin: getNumberFormatted(bmi.value.idealMin),
          idealMax: getNumberFormatted(bmi.value.idealMax),
          weightUnit: bmi.value.unit,
        };
      }

      if (usedCalculator.value === "dailycalories" && calories.value) {
        return { type: usedCalculator.value, min: calories.value.min.toFixed(0), max: calories.value.max.toFixed(0) };
      }

      if (usedCalculator.value === "idealweight" && idealWeight.value) {
        return {
          type: usedCalculator.value,
          min: getNumberFormatted(idealWeight.value.min),
          max: getNumberFormatted(idealWeight.value.max),
          weightUnit: idealWeight.value.unit,
        };
      }
    }

    function handleQueryParameters(queryParameters: QueryParameters) {
      if (queryParameters.isNewFlow() || queryParameters.sessionShouldReset()) {
        $reset();
      }

      usedCalculator.value = queryParameters.parseAndRemove("calculator", Calculator) ?? usedCalculator.value;

      const bmiValue = queryParameters.parseAndRemove("bmi", z.coerce.number());
      const bmiCategory = queryParameters.parseAndRemove("bmiCategory", BmiCategory);
      const caloriesMin = queryParameters.parseAndRemove("caloriesMin", z.coerce.number());
      const caloriesMax = queryParameters.parseAndRemove("caloriesMax", z.coerce.number());
      const idealWeightMin = queryParameters.parseAndRemove("idealWeightMin", z.coerce.number());
      const idealWeightMax = queryParameters.parseAndRemove("idealWeightMax", z.coerce.number());
      const weightUnit = queryParameters.parseAndRemove("weightUnit", UnitMassSchema);

      if (bmiValue && bmiCategory && idealWeightMin && idealWeightMax && weightUnit) {
        bmi.value = {
          value: bmiValue,
          category: bmiCategory,
          idealMin: idealWeightMin,
          idealMax: idealWeightMax,
          unit: weightUnit,
        };
      }

      if (caloriesMin && caloriesMax) {
        calories.value = {
          min: caloriesMin,
          max: caloriesMax,
        };
      }

      if (idealWeightMin && idealWeightMax && weightUnit) {
        idealWeight.value = {
          min: idealWeightMin,
          max: idealWeightMax,
          unit: weightUnit,
        };
      }
    }

    return {
      bmi,
      calories,
      idealWeight,
      usedCalculator,
      getRecommendedGoal,
      handleQueryParameters,
      getCalculatorData,
    };
  },
  {
    persist: {
      pick: ["bmi", "calories", "idealWeight", "usedCalculator"],
      storage: piniaPluginPersistedstate.cookies({
        maxAge: DaysToSeconds(1),
      }),
    },
  },
);
