export const scrambleText = (text: string): string => {
  const characters = text.split("");
  for (let i = characters.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [characters[i], characters[j]] = [characters[j], characters[i]]; // Swap characters
  }
  return characters.join("");
};

export const formatAsFreePrice = (priceString: string) => {
  const allZeros = priceString.replace(/\d/g, "0");
  return allZeros.replace(/0{2,}(?=[.,])/g, "0");
};

export const periodToTranslationKey = (period: string) => {
  return period.replace(/P(\d+)M/, "$1_months");
};

export const formatTestName = (testName: string) => {
  return testName
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const formatVariantName = (variantName: string, testName: string) => {
  variantName = variantName.replace(`${testName}_`, "");

  return variantName
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const removeDateYear = (date: string) => {
  const slashes = date.replace(/(\/|-)\d{4}/, "");
  const trailingSymbols = slashes.replace(/\d{4}./, "");
  return trailingSymbols.replace(/\d{4}/, "");
};

export const stripScreenIdPrefix = (text: string) => {
  return text.replace(/screenid:/, "");
};

export function formatLink(input: string, targetBlank?: string, link?: { [key: string]: string }) {
  const matches = input.match(/\[.*?]/g);

  if (matches !== null && matches.length > 0) {
    return input.replace(/\[.*?]/g, (match) => {
      const text = match.replace(/[[\]]/g, "").split("---");
      let newLink = `<a href="${text[1]}"${targetBlank ? ' target="_blank"' : ""}>${text[0]}</a>`;
      if (link) {
        for (const replaceKey in link) {
          newLink = newLink.replace(replaceKey, link[replaceKey]);
        }
      }
      return newLink;
    });
  }

  return input;
}

export function fillPlaceholderValues(translation: string, placeholderValues: string[]): string {
  const placeholderRegex = /%(\d+\$)?s/g;

  return translation.replace(placeholderRegex, (_, placeholderIndex) => {
    const index = parseInt(placeholderIndex || 1) - 1;
    if (index < 0 || index >= placeholderValues.length) {
      throw new Error("Invalid number of placeholders or values.");
    }
    return placeholderValues[index];
  });
}
