import type { RegistrationParams } from "~/types/onboarding/registrationParams";
import type { OnboardingCondition } from "~/types/onboarding/onboardingScreen";

interface Props {
  registrationParams: Partial<RegistrationParams>;
}

export function getOnboardingConditions(props: Props) {
  function getConditionalValue<T>(conditions: OnboardingCondition<T>): T {
    const options: Record<string, boolean> = {
      GoalMaintainWeight: props.registrationParams.reason === "maintain",
      GoalBuildMuscle: props.registrationParams.reason === "build_muscle",
      GoalGainWeight: props.registrationParams.reason === "gain",
      GoalLoseWeight: props.registrationParams.reason === "lose",
      GoalLooseWeight: props.registrationParams.reason === "lose",
      GoalElse: props.registrationParams.reason === null,
      SexMale: props.registrationParams.sex === "male",
      SexFemale: props.registrationParams.sex === "female",
    };

    const result = conditions.conditions.find((condition) => options[condition.condition]);
    return result ? result.value : conditions.fallback;
  }

  function getOrGetConditionalValue(value: string | OnboardingCondition<string>): string;
  function getOrGetConditionalValue(value: boolean | OnboardingCondition<boolean>): boolean;

  function getOrGetConditionalValue<T>(value: string | boolean | OnboardingCondition<T>): string | boolean | T {
    if (typeof value === "boolean" || typeof value === "string") {
      return value;
    }

    return getConditionalValue(value);
  }

  return {
    getConditionalValue,
    getOrGetConditionalValue,
  };
}
