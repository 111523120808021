<script lang="ts" setup>
import { ref } from "vue";
import type { BaseScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";
import { getOnboardingConditions } from "~/utils/onboardingConditions";

interface Props {
  screen: BaseScreen;
  registrationParams: RegistrationParams;
}

const props = defineProps<Props>();
const { getOrGetConditionalValue } = getOnboardingConditions(props);

const timeout = ref<ReturnType<typeof setTimeout> | null>(null);
const waitTimesList = navigator?.userAgent.includes("playwright-test")
  ? [100, 100, 100, 100]
  : [...useAppConfig().onboardingLoadingPersonalPlanMS];

const circleWaitTime = waitTimesList.reduce((acc, curr) => acc + curr, 0) + 150;

interface Emits {
  (
    e: "next",
    value: {
      params: Partial<RegistrationParams>;
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();

const texts = ref([
  {
    checked: false,
    description: "onboarding.encouraging_flow.plan.load_plan_1.answers",
  },
  {
    checked: false,
    description: "onboarding.encouraging_flow.plan.load_plan_1.calorie_goal",
  },
  {
    checked: false,
    description: "onboarding.encouraging_flow.plan.load_plan_1.progress",
  },
  {
    checked: false,
    description: "onboarding.encouraging_flow.plan.load_plan_1.goals",
  },
]);

const titleTextKey = ref<string>("onboarding.encouraging_flow.plan.load_plan_1.create_plan.title");

const progress = ref<number>(0);

function next() {
  emit("next", {
    params: {},
    nextScreenId: getOrGetConditionalValue(props.screen.nextStep),
  });
}

// // Custom easing function for the counter progression that starts and ends a smidge slower
function easeInOutQuad(t: number) {
  return t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2;
}

function updateProgress() {
  const startTime = performance.now();

  function tick(now: number) {
    const elapsed = now - startTime;
    const prog = Math.min(elapsed / circleWaitTime, 1);
    progress.value = easeInOutQuad(prog) * 100;

    if (prog < 1) {
      requestAnimationFrame(tick);
    }
  }

  requestAnimationFrame(tick);
}

async function startTimers() {
  for (let i = 0; i < texts.value.length; i++) {
    await new Promise((resolve) => {
      timeout.value = setTimeout(() => {
        texts.value[i].checked = true;
        resolve(null);
      }, waitTimesList[i]);
    });
  }

  next();
}

onUnmounted(() => (timeout.value ? clearTimeout(timeout.value) : null));
onMounted(async () => {
  updateProgress();
  await startTimers();
});
</script>

<template>
  <div class="container flex flex-col gap-10 px-bs-container pt-12">
    <div class="relative inline-block w-fit self-center">
      <svg class="size-56" viewBox="0 0 64 64">
        <circle
          :class="progress !== 100 ? 'text-gray-200' : 'text-[#0589d6]'"
          stroke-width="2.5"
          stroke="currentColor"
          fill="none"
          r="30"
          cx="32"
          cy="32"
        />
        <circle
          class="text-[#0589d6]"
          stroke-width="2.5"
          :stroke-dasharray="188.4"
          :stroke-dashoffset="188.4 * (1 - progress / 100)"
          stroke="currentColor"
          fill="none"
          r="30"
          cx="32"
          cy="32"
          transform="rotate(-90 32 32)"
          stroke-linecap="round"
        />
      </svg>
      <div class="absolute inset-0 flex items-center justify-center text-4xl font-medium text-gray-700">
        {{ progress.toFixed(0) }}%
      </div>
    </div>

    <h2 class="m-0 p-0 text-center text-3xl">{{ $t(titleTextKey) }}</h2>

    <div class="mx-auto">
      <ul class="m-0 grid list-none gap-4 p-0">
        <li v-for="text in texts" :key="text.description" class="flex gap-3">
          <img v-if="!text.checked" alt="Unchecked" src="../../../assets/images/circle.svg" />
          <img v-if="text.checked" alt="Checked" src="../../../assets/images/circle_checked.svg" />
          <span class="text-base" :class="text.checked ? '' : 'text-gray-500'">{{ $t(text.description) }} </span>
        </li>
      </ul>
    </div>
  </div>
</template>
