import { default as cancelGo57fiTF8RMeta } from "/app/pages/account/cancel.vue?macro=true";
import { default as confirm_45emailhjUdkJRRNuMeta } from "/app/pages/account/confirm-email.vue?macro=true";
import { default as delete_45accountCGhZeN3GWcMeta } from "/app/pages/account/delete-account.vue?macro=true";
import { default as forgot_45passworddr6WGYIGtdMeta } from "/app/pages/account/forgot-password.vue?macro=true";
import { default as indexQWKFaMHH2OMeta } from "/app/pages/account/index.vue?macro=true";
import { default as loginex0V7Cf45hMeta } from "/app/pages/account/login.vue?macro=true";
import { default as new_45passwordmRtLt1aLj5Meta } from "/app/pages/account/new-password.vue?macro=true";
import { default as registeroA1D30gU6hMeta } from "/app/pages/account/register.vue?macro=true";
import { default as unsubscribe_45newsletterxlk27cXlFhMeta } from "/app/pages/account/unsubscribe-newsletter.vue?macro=true";
import { default as htlpcfWXdd36IkMeta } from "/app/pages/awin/htlp.vue?macro=true";
import { default as affiliateN8SwMZ5cdjMeta } from "/app/pages/become-a-partner/affiliate.vue?macro=true";
import { default as content_45creatorniMhd5EpvfMeta } from "/app/pages/become-a-partner/content-creator.vue?macro=true";
import { default as indexdcXBDICtA3Meta } from "/app/pages/become-a-partner/index.vue?macro=true";
import { default as _08af9eu0fa903fguMXts3vaJQDMeta } from "/app/pages/developer/08af9eu0fa903fgu.vue?macro=true";
import { default as dyil1kjh3lkjh3kjhl7280Cr4Tg5hMeta } from "/app/pages/developer/dyil1kjh3lkjh3kjhl7.vue?macro=true";
import { default as _91_91error_93_93P9JwZVovfQMeta } from "/app/pages/error/[[error]].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91_91id_93_93sQGGepEuDMMeta } from "/app/pages/onboarding/[[instructions]]/[[id]].vue?macro=true";
import { default as checkoutT3T29qBgtyMeta } from "/app/pages/onboarding/checkout.vue?macro=true";
import { default as coupon7ZFRX129w8Meta } from "/app/pages/onboarding/coupon.vue?macro=true";
import { default as finalize_45accountRyxqbJDHelMeta } from "/app/pages/onboarding/finalize-account.vue?macro=true";
import { default as successE3IuMXkb0JMeta } from "/app/pages/onboarding/success.vue?macro=true";
import { default as indexua9nYrNvrBMeta } from "/app/pages/promo-code/index.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "account-cancel",
    path: "/account/cancel",
    component: () => import("/app/pages/account/cancel.vue")
  },
  {
    name: "account-confirm-email",
    path: "/account/confirm-email",
    component: () => import("/app/pages/account/confirm-email.vue")
  },
  {
    name: "account-delete-account",
    path: "/account/delete-account",
    component: () => import("/app/pages/account/delete-account.vue")
  },
  {
    name: "account-forgot-password",
    path: "/account/forgot-password",
    component: () => import("/app/pages/account/forgot-password.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexQWKFaMHH2OMeta || {},
    component: () => import("/app/pages/account/index.vue")
  },
  {
    name: "account-login",
    path: "/account/login",
    meta: loginex0V7Cf45hMeta || {},
    component: () => import("/app/pages/account/login.vue")
  },
  {
    name: "account-new-password",
    path: "/account/new-password",
    component: () => import("/app/pages/account/new-password.vue")
  },
  {
    name: "account-register",
    path: "/account/register",
    meta: registeroA1D30gU6hMeta || {},
    component: () => import("/app/pages/account/register.vue")
  },
  {
    name: "account-unsubscribe-newsletter",
    path: "/account/unsubscribe-newsletter",
    component: () => import("/app/pages/account/unsubscribe-newsletter.vue")
  },
  {
    name: "awin-htlp",
    path: "/awin/htlp",
    component: () => import("/app/pages/awin/htlp.vue")
  },
  {
    name: "become-a-partner-affiliate",
    path: "/become-a-partner/affiliate",
    component: () => import("/app/pages/become-a-partner/affiliate.vue")
  },
  {
    name: "become-a-partner-content-creator",
    path: "/become-a-partner/content-creator",
    component: () => import("/app/pages/become-a-partner/content-creator.vue")
  },
  {
    name: "become-a-partner",
    path: "/become-a-partner",
    component: () => import("/app/pages/become-a-partner/index.vue")
  },
  {
    name: "developer-08af9eu0fa903fgu",
    path: "/developer/08af9eu0fa903fgu",
    component: () => import("/app/pages/developer/08af9eu0fa903fgu.vue")
  },
  {
    name: "developer-dyil1kjh3lkjh3kjhl7",
    path: "/developer/dyil1kjh3lkjh3kjhl7",
    component: () => import("/app/pages/developer/dyil1kjh3lkjh3kjhl7.vue")
  },
  {
    name: "error-error",
    path: "/error/:error?",
    component: () => import("/app/pages/error/[[error]].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "onboarding-instructions-id",
    path: "/onboarding/:instructions?/:id?",
    meta: _91_91id_93_93sQGGepEuDMMeta || {},
    component: () => import("/app/pages/onboarding/[[instructions]]/[[id]].vue")
  },
  {
    name: "onboarding-checkout",
    path: "/onboarding/checkout",
    meta: checkoutT3T29qBgtyMeta || {},
    component: () => import("/app/pages/onboarding/checkout.vue")
  },
  {
    name: "onboarding-coupon",
    path: "/onboarding/coupon",
    meta: coupon7ZFRX129w8Meta || {},
    component: () => import("/app/pages/onboarding/coupon.vue")
  },
  {
    name: "onboarding-finalize-account",
    path: "/onboarding/finalize-account",
    meta: finalize_45accountRyxqbJDHelMeta || {},
    component: () => import("/app/pages/onboarding/finalize-account.vue")
  },
  {
    name: "onboarding-success",
    path: "/onboarding/success",
    component: () => import("/app/pages/onboarding/success.vue")
  },
  {
    name: "promo-code",
    path: "/promo-code",
    component: () => import("/app/pages/promo-code/index.vue")
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/lp/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/pro/login",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/pro/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/pro-signup/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/unsubscribe",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/password",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/register",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/confirmation",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/checkout",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/login",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/delete-account",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/apple-touch-icon.png",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/apple-touch-icon-120x120.png",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/apple-touch-icon-precomposed.png",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/apple-touch-icon-120x120-precomposed.png",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/apple-touch-icon-152x152-precomposed.png",
    component: component_45stubnO9MU04yTU
  }
]