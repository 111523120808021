import type { SingleChoiceScreen } from "~/types/onboarding/onboardingScreen";
import type { GeneratorInput } from "~/resources/onboarding/generators/index";
import { ageToAgeRange, getAgeFromBirthdate } from "~/utils/age";

export default function generate(data: GeneratorInput): Partial<SingleChoiceScreen> | undefined {
  if (!data.registrationParams.reason && !data.registrationParams.sex && !(data.singleChoiceSelectedOptions["age-range"] || data.registrationParams.dateOfBirth)) {
    return undefined;
  }

  if (!data.singleChoiceSelectedOptions["age-range"] && data.registrationParams.dateOfBirth) {
    const calculatedAge = getAgeFromBirthdate(data.registrationParams.dateOfBirth);
    data.singleChoiceSelectedOptions["age-range"] = ageToAgeRange(calculatedAge) as string;
  }

  const gender = data.registrationParams.sex === "male" ? "Male" : "Female";
  const ageGroup = data.singleChoiceSelectedOptions["age-range"];
  const goal = (() => {
    switch (data.registrationParams.reason) {
      case "lose":
        return "Lose Weight";
      case "build_muscle":
        return "Build Muscle";
      case "gain":
        return "Gain Weight";
      case "maintain":
        return "Maintain Weight";
      default:
        return "Something Else";
    }
  })();

  const match = dataMatrix.find((d) => d.Goal === goal && d["Age Group"] === ageGroup && d.Gender === gender);

  if (!match) {
    return undefined;
  }

  const captionGenderKey = gender === "Male" ? "men" : "women";
  const captionAgeGroupKey = ageGroup === "16-29" ? "16_29" : ageGroup === "30-49" ? "30_49" : ageGroup === "50-69" ? "50_69" : ageGroup === "70+" ? "over_70" : null;
  const captionTranslationKey = `app.profunnel.motivation.question_screen.question.subheading.${captionGenderKey}_${captionAgeGroupKey}`;

  return {
    titleTranslationKey: { conditions: [], fallback: match.Question },
    captionTranslationKey: { conditions: [], fallback: captionTranslationKey },
    options: [1, 2, 3, 4, 5].map((number) => ({
      emoji: match[`Emoji ${number}` as never],
      translationKey: match[`Option ${number}` as never],
      trackingName: match[`Option ${number}` as never],
      nextStep: { conditions: [], fallback: optionToNextStep(match[`Option ${number}` as never]) },
      visible: { conditions: [], fallback: true }
    }))
  };
}

function optionToNextStep(translationKey: string) {
  const translationKeyToNextStepMap: Record<string, string> = {
    "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feeling_proud_mirror_to": "screenid:app.profunnel.motivation.affirmation_screen.self_confidence_appearance",
    "app.profunnel.motivation.question_screen.option.self_confidence_appearance.fit_in_clothes_again": "screenid:app.profunnel.motivation.affirmation_screen.self_confidence_appearance",
    "app.profunnel.motivation.question_screen.option.self_confidence_appearance.look_more_attractive": "screenid:app.profunnel.motivation.affirmation_screen.self_confidence_appearance",
    "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feel_fit_in_better": "screenid:app.profunnel.motivation.affirmation_screen.self_confidence_appearance",
    "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feel_more_confident": "screenid:app.profunnel.motivation.affirmation_screen.self_confidence_appearance",
    "app.profunnel.motivation.question_screen.option.self_confidence_appearance.boosting_confidence": "screenid:app.profunnel.motivation.affirmation_screen.self_confidence_appearance",
    "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feel_attractive": "screenid:app.profunnel.motivation.affirmation_screen.self_confidence_appearance",
    "app.profunnel.motivation.question_screen.option.health_wellness.improve_overall_wellbeing": "screenid:app.profunnel.motivation.affirmation_screen.health_wellness",
    "app.profunnel.motivation.question_screen.option.health_wellness.improving_overall_wellbeing": "screenid:app.profunnel.motivation.affirmation_screen.health_wellness",
    "app.profunnel.motivation.question_screen.option.health_wellness.manage_chronic_diseases": "screenid:app.profunnel.motivation.affirmation_screen.health_wellness",
    "app.profunnel.motivation.question_screen.option.health_wellness.improve_mobility_energy": "screenid:app.profunnel.motivation.affirmation_screen.health_wellness",
    "app.profunnel.motivation.question_screen.option.health_wellness.be_healthy_prevent_diseases": "screenid:app.profunnel.motivation.affirmation_screen.health_wellness",
    "app.profunnel.motivation.question_screen.option.health_wellness.prevent_diseases": "screenid:app.profunnel.motivation.affirmation_screen.health_wellness",
    "app.profunnel.motivation.question_screen.option.health_wellness.recover_eating_disorder": "screenid:app.profunnel.motivation.affirmation_screen.health_wellness",
    "app.profunnel.motivation.question_screen.option.health_wellness.recover_health_issues": "screenid:app.profunnel.motivation.affirmation_screen.health_wellness",
    "app.profunnel.motivation.question_screen.option.health_wellness.cardio_health": "screenid:app.profunnel.motivation.affirmation_screen.health_wellness",
    "app.profunnel.motivation.question_screen.option.health_wellness.manage_health_prevent_diseases": "screenid:app.profunnel.motivation.affirmation_screen.health_wellness",
    "app.profunnel.motivation.question_screen.option.health_wellness.stay_healthy_in_shape": "screenid:app.profunnel.motivation.affirmation_screen.health_wellness",
    "app.profunnel.motivation.question_screen.option.health_wellness.stay_healthy_prevent_diseases": "screenid:app.profunnel.motivation.affirmation_screen.health_wellness",
    "app.profunnel.motivation.question_screen.option.health_wellness.manage_stress_levels": "screenid:app.profunnel.motivation.affirmation_screen.health_wellness",
    "app.profunnel.motivation.question_screen.option.health_wellness.feel_good_inside_out": "screenid:app.profunnel.motivation.affirmation_screen.health_wellness",
    "app.profunnel.motivation.question_screen.option.energy_vitality.increase_strength_energy": "screenid:app.profunnel.motivation.affirmation_screen.energy_vitality",
    "app.profunnel.motivation.question_screen.option.energy_vitality.energy_for_hobbies": "screenid:app.profunnel.motivation.affirmation_screen.energy_vitality",
    "app.profunnel.motivation.question_screen.option.energy_vitality.maintain_strength_energy": "screenid:app.profunnel.motivation.affirmation_screen.energy_vitality",
    "app.profunnel.motivation.question_screen.option.energy_vitality.energy_levels_high_to": "screenid:app.profunnel.motivation.affirmation_screen.energy_vitality",
    "app.profunnel.motivation.question_screen.option.energy_vitality.more_energy_overall": "screenid:app.profunnel.motivation.affirmation_screen.energy_vitality",
    "app.profunnel.motivation.question_screen.option.energy_vitality.more_energy_day": "screenid:app.profunnel.motivation.affirmation_screen.energy_vitality",
    "app.profunnel.motivation.question_screen.option.energy_vitality.confident_vibrant_golden_years_to": "screenid:app.profunnel.motivation.affirmation_screen.energy_vitality",
    "app.profunnel.motivation.question_screen.option.performance_fitness.athletic_performance": "screenid:app.profunnel.motivation.affirmation_screen.performance_fitness",
    "app.profunnel.motivation.question_screen.option.performance_fitness.increase_fitness_level": "screenid:app.profunnel.motivation.affirmation_screen.performance_fitness",
    "app.profunnel.motivation.question_screen.option.performance_fitness.fit_agile": "screenid:app.profunnel.motivation.affirmation_screen.performance_fitness",
    "app.profunnel.motivation.question_screen.option.performance_fitness.be_fit_agile": "screenid:app.profunnel.motivation.affirmation_screen.performance_fitness",
    "app.profunnel.motivation.question_screen.option.performance_fitness.be_more_athletic": "screenid:app.profunnel.motivation.affirmation_screen.performance_fitness",
    "app.profunnel.motivation.question_screen.option.performance_fitness.being_more_athletic": "screenid:app.profunnel.motivation.affirmation_screen.performance_fitness",
    "app.profunnel.motivation.question_screen.option.performance_fitness.get_in_shape": "screenid:app.profunnel.motivation.affirmation_screen.performance_fitness",
    "app.profunnel.motivation.question_screen.option.performance_fitness.improve_strength": "screenid:app.profunnel.motivation.affirmation_screen.performance_fitness",
    "app.profunnel.motivation.question_screen.option.performance_fitness.maintain_fitness": "screenid:app.profunnel.motivation.affirmation_screen.performance_fitness",
    "app.profunnel.motivation.question_screen.option.performance_fitness.maintain_athletic_performance": "screenid:app.profunnel.motivation.affirmation_screen.performance_fitness",
    "app.profunnel.motivation.question_screen.option.performance_fitness.stay_confident_shape": "screenid:app.profunnel.motivation.affirmation_screen.performance_fitness",
    "app.profunnel.motivation.question_screen.option.performance_fitness.stay_fit_agile": "screenid:app.profunnel.motivation.affirmation_screen.performance_fitness",
    "app.profunnel.motivation.question_screen.option.performance_fitness.stay_fit_hobbies": "screenid:app.profunnel.motivation.affirmation_screen.performance_fitness",
    "app.profunnel.motivation.question_screen.option.performance_fitness.independence_mobility": "screenid:app.profunnel.motivation.affirmation_screen.performance_fitness",
    "app.profunnel.motivation.question_screen.option.performance_fitness.stay_in_shape_active": "screenid:app.profunnel.motivation.affirmation_screen.performance_fitness",
    "app.profunnel.motivation.question_screen.option.social_family.keep_up_grandkids": "screenid:app.profunnel.motivation.affirmation_screen.social_family",
    "app.profunnel.motivation.question_screen.option.social_family.keep_up_family_life": "screenid:app.profunnel.motivation.affirmation_screen.social_family",
    "app.profunnel.motivation.question_screen.option.social_family.fit_active_family": "screenid:app.profunnel.motivation.affirmation_screen.social_family",
    "app.profunnel.motivation.question_screen.option.social_family.active_engaged_family": "screenid:app.profunnel.motivation.affirmation_screen.social_family",
    "app.profunnel.motivation.question_screen.option.social_family.example_family_grandkids": "screenid:app.profunnel.motivation.affirmation_screen.social_family",
    "app.profunnel.motivation.question_screen.option.social_family.set_example_family_to": "screenid:app.profunnel.motivation.affirmation_screen.social_family",
    "app.profunnel.motivation.question_screen.option.energy_vitality.energy_keep_up_kids": "screenid:app.profunnel.motivation.affirmation_screen.social_family",
    "app.profunnel.motivation.question_screen.option.something_else": "screenid:onboarding.encouraging_flow.plan.load_plan_1"
  };

  return translationKeyToNextStepMap[translationKey];
}

const dataMatrix: Array<{
  Goal: string;
  "Age Group": string;
  Gender: string;
  Question: string;
  "Emoji 1": string;
  "Option 1": string;
  "Emoji 2": string;
  "Option 2": string;
  "Emoji 3": string;
  "Option 3": string;
  "Emoji 4": string;
  "Option 4": string;
  "Emoji 5": string;
  "Option 5": string;
}> = [
  {
    Goal: "Lose Weight",
    "Age Group": "16-29",
    Gender: "Female",
    Question: "app.profunnel.motivation.question_screen.question.heading.lose_weight",
    "Emoji 1": "🪞",
    "Option 1": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feeling_proud_mirror_to",
    "Emoji 2": "👕",
    "Option 2": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.fit_in_clothes_again",
    "Emoji 3": "✨",
    "Option 3": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.look_more_attractive",
    "Emoji 4": "🤝",
    "Option 4": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feel_fit_in_better",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Lose Weight",
    "Age Group": "16-29",
    Gender: "Male",
    Question: "app.profunnel.motivation.question_screen.question.heading.lose_weight",
    "Emoji 1": "🪞",
    "Option 1": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feeling_proud_mirror_to",
    "Emoji 2": "✨",
    "Option 2": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.look_more_attractive",
    "Emoji 3": "🏅",
    "Option 3": "app.profunnel.motivation.question_screen.option.performance_fitness.athletic_performance",
    "Emoji 4": "🤝",
    "Option 4": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feel_fit_in_better",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Lose Weight",
    "Age Group": "30-49",
    Gender: "Female",
    Question: "app.profunnel.motivation.question_screen.question.heading.lose_weight",
    "Emoji 1": "🦸",
    "Option 1": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feel_more_confident",
    "Emoji 2": "👕",
    "Option 2": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.fit_in_clothes_again",
    "Emoji 3": "🧘",
    "Option 3": "app.profunnel.motivation.question_screen.option.health_wellness.improve_overall_wellbeing",
    "Emoji 4": "👨‍👩‍👧‍👦",
    "Option 4": "app.profunnel.motivation.question_screen.option.social_family.set_example_family_to",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Lose Weight",
    "Age Group": "30-49",
    Gender: "Male",
    Question: "app.profunnel.motivation.question_screen.question.heading.lose_weight",
    "Emoji 1": "🦸",
    "Option 1": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feel_more_confident",
    "Emoji 2": "🩺",
    "Option 2": "app.profunnel.motivation.question_screen.option.health_wellness.manage_chronic_diseases",
    "Emoji 3": "🏋️",
    "Option 3": "app.profunnel.motivation.question_screen.option.performance_fitness.increase_fitness_level",
    "Emoji 4": "👨‍👩‍👧‍👦",
    "Option 4": "app.profunnel.motivation.question_screen.option.social_family.set_example_family_to",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Lose Weight",
    "Age Group": "50-69",
    Gender: "Female",
    Question: "app.profunnel.motivation.question_screen.question.heading.lose_weight",
    "Emoji 1": "🚴",
    "Option 1": "app.profunnel.motivation.question_screen.option.health_wellness.improve_mobility_energy",
    "Emoji 2": "🪞",
    "Option 2": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feeling_proud_mirror_to",
    "Emoji 3": "🩺",
    "Option 3": "app.profunnel.motivation.question_screen.option.health_wellness.manage_chronic_diseases",
    "Emoji 4": "👨‍👩‍👧‍👦",
    "Option 4": "app.profunnel.motivation.question_screen.option.social_family.keep_up_grandkids",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Lose Weight",
    "Age Group": "50-69",
    Gender: "Male",
    Question: "app.profunnel.motivation.question_screen.question.heading.lose_weight",
    "Emoji 1": "🩺",
    "Option 1": "app.profunnel.motivation.question_screen.option.health_wellness.manage_chronic_diseases",
    "Emoji 2": "🚴",
    "Option 2": "app.profunnel.motivation.question_screen.option.health_wellness.improve_mobility_energy",
    "Emoji 3": "🧘",
    "Option 3": "app.profunnel.motivation.question_screen.option.health_wellness.improve_overall_wellbeing",
    "Emoji 4": "👨‍👩‍👧‍👦",
    "Option 4": "app.profunnel.motivation.question_screen.option.social_family.keep_up_grandkids",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Lose Weight",
    "Age Group": "70+",
    Gender: "Female",
    Question: "app.profunnel.motivation.question_screen.question.heading.lose_weight",
    "Emoji 1": "🚴",
    "Option 1": "app.profunnel.motivation.question_screen.option.performance_fitness.fit_agile",
    "Emoji 2": "🩺",
    "Option 2": "app.profunnel.motivation.question_screen.option.health_wellness.be_healthy_prevent_diseases",
    "Emoji 3": "🌟",
    "Option 3": "app.profunnel.motivation.question_screen.option.energy_vitality.confident_vibrant_golden_years_to",
    "Emoji 4": "👨‍👩‍👧‍👦",
    "Option 4": "app.profunnel.motivation.question_screen.option.social_family.keep_up_grandkids",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Lose Weight",
    "Age Group": "70+",
    Gender: "Male",
    Question: "app.profunnel.motivation.question_screen.question.heading.lose_weight",
    "Emoji 1": "🚴",
    "Option 1": "app.profunnel.motivation.question_screen.option.performance_fitness.fit_agile",
    "Emoji 2": "🩺",
    "Option 2": "app.profunnel.motivation.question_screen.option.health_wellness.be_healthy_prevent_diseases",
    "Emoji 3": "🌟",
    "Option 3": "app.profunnel.motivation.question_screen.option.energy_vitality.confident_vibrant_golden_years_to",
    "Emoji 4": "👨‍👩‍👧‍👦",
    "Option 4": "app.profunnel.motivation.question_screen.option.social_family.keep_up_grandkids",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Gain Weight",
    "Age Group": "16-29",
    Gender: "Female",
    Question: "app.profunnel.motivation.question_screen.question.heading.gain_weight",
    "Emoji 1": "💪",
    "Option 1": "app.profunnel.motivation.question_screen.option.energy_vitality.increase_strength_energy",
    "Emoji 2": "🪞",
    "Option 2": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feeling_proud_mirror_to",
    "Emoji 3": "🩺",
    "Option 3": "app.profunnel.motivation.question_screen.option.health_wellness.recover_eating_disorder",
    "Emoji 4": "🦸",
    "Option 4": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feel_more_confident",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Gain Weight",
    "Age Group": "16-29",
    Gender: "Male",
    Question: "app.profunnel.motivation.question_screen.question.heading.gain_weight",
    "Emoji 1": "🏃",
    "Option 1": "app.profunnel.motivation.question_screen.option.performance_fitness.be_more_athletic",
    "Emoji 2": "💪",
    "Option 2": "app.profunnel.motivation.question_screen.option.energy_vitality.increase_strength_energy",
    "Emoji 3": "🪞",
    "Option 3": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feeling_proud_mirror_to",
    "Emoji 4": "✨",
    "Option 4": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.look_more_attractive",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Gain Weight",
    "Age Group": "30-49",
    Gender: "Female",
    Question: "app.profunnel.motivation.question_screen.question.heading.gain_weight",
    "Emoji 1": "🧘",
    "Option 1": "app.profunnel.motivation.question_screen.option.health_wellness.improve_overall_wellbeing",
    "Emoji 2": "🪞",
    "Option 2": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feeling_proud_mirror_to",
    "Emoji 3": "🦸",
    "Option 3": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feel_more_confident",
    "Emoji 4": "👨‍👩‍👧‍👦",
    "Option 4": "app.profunnel.motivation.question_screen.option.social_family.keep_up_family_life",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Gain Weight",
    "Age Group": "30-49",
    Gender: "Male",
    Question: "app.profunnel.motivation.question_screen.question.heading.gain_weight",
    "Emoji 1": "🏃",
    "Option 1": "app.profunnel.motivation.question_screen.option.performance_fitness.be_more_athletic",
    "Emoji 2": "👨‍👩‍👧‍👦",
    "Option 2": "app.profunnel.motivation.question_screen.option.social_family.keep_up_family_life",
    "Emoji 3": "🦸",
    "Option 3": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feel_more_confident",
    "Emoji 4": "⚡",
    "Option 4": "app.profunnel.motivation.question_screen.option.energy_vitality.energy_for_hobbies",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Gain Weight",
    "Age Group": "50-69",
    Gender: "Female",
    Question: "app.profunnel.motivation.question_screen.question.heading.gain_weight",
    "Emoji 1": "🧘",
    "Option 1": "app.profunnel.motivation.question_screen.option.health_wellness.improve_overall_wellbeing",
    "Emoji 2": "🚴",
    "Option 2": "app.profunnel.motivation.question_screen.option.performance_fitness.fit_agile",
    "Emoji 3": "🪞",
    "Option 3": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feeling_proud_mirror_to",
    "Emoji 4": "🩺",
    "Option 4": "app.profunnel.motivation.question_screen.option.health_wellness.recover_health_issues",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Gain Weight",
    "Age Group": "50-69",
    Gender: "Male",
    Question: "app.profunnel.motivation.question_screen.question.heading.gain_weight",
    "Emoji 1": "🧘",
    "Option 1": "app.profunnel.motivation.question_screen.option.health_wellness.improve_overall_wellbeing",
    "Emoji 2": "🚴",
    "Option 2": "app.profunnel.motivation.question_screen.option.performance_fitness.fit_agile",
    "Emoji 3": "🪞",
    "Option 3": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feeling_proud_mirror_to",
    "Emoji 4": "🩺",
    "Option 4": "app.profunnel.motivation.question_screen.option.health_wellness.recover_health_issues",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Gain Weight",
    "Age Group": "70+",
    Gender: "Female",
    Question: "app.profunnel.motivation.question_screen.question.heading.gain_weight",
    "Emoji 1": "💪",
    "Option 1": "app.profunnel.motivation.question_screen.option.energy_vitality.maintain_strength_energy",
    "Emoji 2": "🧘",
    "Option 2": "app.profunnel.motivation.question_screen.option.health_wellness.improve_overall_wellbeing",
    "Emoji 3": "🚴",
    "Option 3": "app.profunnel.motivation.question_screen.option.performance_fitness.fit_agile",
    "Emoji 4": "🩺",
    "Option 4": "app.profunnel.motivation.question_screen.option.health_wellness.recover_health_issues",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Gain Weight",
    "Age Group": "70+",
    Gender: "Male",
    Question: "app.profunnel.motivation.question_screen.question.heading.gain_weight",
    "Emoji 1": "💪",
    "Option 1": "app.profunnel.motivation.question_screen.option.energy_vitality.maintain_strength_energy",
    "Emoji 2": "🧘",
    "Option 2": "app.profunnel.motivation.question_screen.option.health_wellness.improve_overall_wellbeing",
    "Emoji 3": "🚴",
    "Option 3": "app.profunnel.motivation.question_screen.option.performance_fitness.fit_agile",
    "Emoji 4": "🩺",
    "Option 4": "app.profunnel.motivation.question_screen.option.health_wellness.recover_health_issues",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Build Muscle",
    "Age Group": "16-29",
    Gender: "Female",
    Question: "app.profunnel.motivation.question_screen.question.heading.build_muscle",
    "Emoji 1": "🪞",
    "Option 1": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feeling_proud_mirror_to",
    "Emoji 2": "🦸",
    "Option 2": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.boosting_confidence_to",
    "Emoji 3": "🏃",
    "Option 3": "app.profunnel.motivation.question_screen.option.performance_fitness.be_more_athletic",
    "Emoji 4": "💪",
    "Option 4": "app.profunnel.motivation.question_screen.option.performance_fitness.get_in_shape",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Build Muscle",
    "Age Group": "16-29",
    Gender: "Male",
    Question: "app.profunnel.motivation.question_screen.question.heading.build_muscle",
    "Emoji 1": "🪞",
    "Option 1": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feeling_proud_mirror_to",
    "Emoji 2": "🦸",
    "Option 2": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.boosting_confidence_to",
    "Emoji 3": "🏃",
    "Option 3": "app.profunnel.motivation.question_screen.option.performance_fitness.be_more_athletic",
    "Emoji 4": "💪",
    "Option 4": "app.profunnel.motivation.question_screen.option.performance_fitness.get_in_shape",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Build Muscle",
    "Age Group": "30-49",
    Gender: "Female",
    Question: "app.profunnel.motivation.question_screen.question.heading.build_muscle",
    "Emoji 1": "🪞",
    "Option 1": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feeling_proud_mirror_to",
    "Emoji 2": "🦸",
    "Option 2": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.boosting_confidence_to",
    "Emoji 3": "🏃",
    "Option 3": "app.profunnel.motivation.question_screen.option.performance_fitness.maintain_fitness",
    "Emoji 4": "💪",
    "Option 4": "app.profunnel.motivation.question_screen.option.performance_fitness.improve_strength",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Build Muscle",
    "Age Group": "30-49",
    Gender: "Male",
    Question: "app.profunnel.motivation.question_screen.question.heading.build_muscle",
    "Emoji 1": "🪞",
    "Option 1": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feeling_proud_mirror_to",
    "Emoji 2": "🦸",
    "Option 2": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.boosting_confidence_to",
    "Emoji 3": "🏃",
    "Option 3": "app.profunnel.motivation.question_screen.option.performance_fitness.maintain_fitness",
    "Emoji 4": "💪",
    "Option 4": "app.profunnel.motivation.question_screen.option.performance_fitness.improve_strength",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Build Muscle",
    "Age Group": "50-69",
    Gender: "Female",
    Question: "app.profunnel.motivation.question_screen.question.heading.build_muscle",
    "Emoji 1": "💪",
    "Option 1": "app.profunnel.motivation.question_screen.option.energy_vitality.maintain_strength_energy",
    "Emoji 2": "🪞",
    "Option 2": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feeling_proud_mirror_to",
    "Emoji 3": "🩺",
    "Option 3": "app.profunnel.motivation.question_screen.option.health_wellness.be_healthy_prevent_diseases",
    "Emoji 4": "💚",
    "Option 4": "app.profunnel.motivation.question_screen.option.health_wellness.cardio_health",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Build Muscle",
    "Age Group": "50-69",
    Gender: "Male",
    Question: "app.profunnel.motivation.question_screen.question.heading.build_muscle",
    "Emoji 1": "💪",
    "Option 1": "app.profunnel.motivation.question_screen.option.energy_vitality.maintain_strength_energy",
    "Emoji 2": "🪞",
    "Option 2": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feeling_proud_mirror_to",
    "Emoji 3": "🩺",
    "Option 3": "app.profunnel.motivation.question_screen.option.health_wellness.manage_health_prevent_diseases",
    "Emoji 4": "💚",
    "Option 4": "app.profunnel.motivation.question_screen.option.health_wellness.cardio_health",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Build Muscle",
    "Age Group": "70+",
    Gender: "Female",
    Question: "app.profunnel.motivation.question_screen.question.heading.build_muscle",
    "Emoji 1": "💪",
    "Option 1": "app.profunnel.motivation.question_screen.option.energy_vitality.maintain_strength_energy",
    "Emoji 2": "🪞",
    "Option 2": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feeling_proud_mirror_to",
    "Emoji 3": "🚴",
    "Option 3": "app.profunnel.motivation.question_screen.option.performance_fitness.independence_mobility",
    "Emoji 4": "💚",
    "Option 4": "app.profunnel.motivation.question_screen.option.health_wellness.cardio_health",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Build Muscle",
    "Age Group": "70+",
    Gender: "Male",
    Question: "app.profunnel.motivation.question_screen.question.heading.build_muscle",
    "Emoji 1": "💪",
    "Option 1": "app.profunnel.motivation.question_screen.option.energy_vitality.maintain_strength_energy",
    "Emoji 2": "🪞",
    "Option 2": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feeling_proud_mirror_to",
    "Emoji 3": "🚴",
    "Option 3": "app.profunnel.motivation.question_screen.option.performance_fitness.independence_mobility",
    "Emoji 4": "💚",
    "Option 4": "app.profunnel.motivation.question_screen.option.health_wellness.cardio_health",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Maintain Weight",
    "Age Group": "16-29",
    Gender: "Female",
    Question: "app.profunnel.motivation.question_screen.question.heading.maintain_weight",
    "Emoji 1": "🪞",
    "Option 1": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feeling_proud_mirror_to",
    "Emoji 2": "🌟",
    "Option 2": "app.profunnel.motivation.question_screen.option.performance_fitness.stay_confident_shape",
    "Emoji 3": "⚡",
    "Option 3": "app.profunnel.motivation.question_screen.option.energy_vitality.energy_levels_high_to",
    "Emoji 4": "✨",
    "Option 4": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feel_attractive",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Maintain Weight",
    "Age Group": "16-29",
    Gender: "Male",
    Question: "app.profunnel.motivation.question_screen.question.heading.maintain_weight",
    "Emoji 1": "🪞",
    "Option 1": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feeling_proud_mirror_to",
    "Emoji 2": "🏅",
    "Option 2": "app.profunnel.motivation.question_screen.option.performance_fitness.maintain_athletic_performance",
    "Emoji 3": "⚡",
    "Option 3": "app.profunnel.motivation.question_screen.option.energy_vitality.energy_levels_high_to",
    "Emoji 4": "✨",
    "Option 4": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feel_attractive",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Maintain Weight",
    "Age Group": "30-49",
    Gender: "Female",
    Question: "app.profunnel.motivation.question_screen.question.heading.maintain_weight",
    "Emoji 1": "🪞",
    "Option 1": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feeling_proud_mirror_to",
    "Emoji 2": "👨‍👩‍👧‍👦",
    "Option 2": "app.profunnel.motivation.question_screen.option.social_family.fit_active_family",
    "Emoji 3": "⚡",
    "Option 3": "app.profunnel.motivation.question_screen.option.energy_vitality.energy_levels_high_to",
    "Emoji 4": "💪",
    "Option 4": "app.profunnel.motivation.question_screen.option.health_wellness.stay_healthy_in_shape",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Maintain Weight",
    "Age Group": "30-49",
    Gender: "Male",
    Question: "app.profunnel.motivation.question_screen.question.heading.maintain_weight",
    "Emoji 1": "🪞",
    "Option 1": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feeling_proud_mirror_to",
    "Emoji 2": "👨‍👩‍👧‍👦",
    "Option 2": "app.profunnel.motivation.question_screen.option.social_family.fit_active_family",
    "Emoji 3": "⚡",
    "Option 3": "app.profunnel.motivation.question_screen.option.energy_vitality.energy_levels_high_to",
    "Emoji 4": "💪",
    "Option 4": "app.profunnel.motivation.question_screen.option.health_wellness.stay_healthy_in_shape",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Maintain Weight",
    "Age Group": "50-69",
    Gender: "Female",
    Question: "app.profunnel.motivation.question_screen.question.heading.maintain_weight",
    "Emoji 1": "✨",
    "Option 1": "app.profunnel.motivation.question_screen.option.health_wellness.feel_good_inside_out",
    "Emoji 2": "🏃",
    "Option 2": "app.profunnel.motivation.question_screen.option.performance_fitness.stay_in_shape_active",
    "Emoji 3": "🩺",
    "Option 3": "app.profunnel.motivation.question_screen.option.health_wellness.stay_healthy_prevent_diseases",
    "Emoji 4": "⚡",
    "Option 4": "app.profunnel.motivation.question_screen.option.energy_vitality.energy_levels_high_to",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Maintain Weight",
    "Age Group": "50-69",
    Gender: "Male",
    Question: "app.profunnel.motivation.question_screen.question.heading.maintain_weight",
    "Emoji 1": "✨",
    "Option 1": "app.profunnel.motivation.question_screen.option.health_wellness.feel_good_inside_out",
    "Emoji 2": "🏃",
    "Option 2": "app.profunnel.motivation.question_screen.option.performance_fitness.stay_in_shape_active",
    "Emoji 3": "🩺",
    "Option 3": "app.profunnel.motivation.question_screen.option.health_wellness.stay_healthy_prevent_diseases",
    "Emoji 4": "💪",
    "Option 4": "app.profunnel.motivation.question_screen.option.performance_fitness.stay_fit_hobbies",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Maintain Weight",
    "Age Group": "70+",
    Gender: "Female",
    Question: "app.profunnel.motivation.question_screen.question.heading.maintain_weight",
    "Emoji 1": "🚴",
    "Option 1": "app.profunnel.motivation.question_screen.option.performance_fitness.stay_fit_agile",
    "Emoji 2": "🧘",
    "Option 2": "app.profunnel.motivation.question_screen.option.health_wellness.improve_overall_wellbeing",
    "Emoji 3": "⚡",
    "Option 3": "app.profunnel.motivation.question_screen.option.energy_vitality.energy_levels_high_to",
    "Emoji 4": "🌟",
    "Option 4": "app.profunnel.motivation.question_screen.option.energy_vitality.confident_vibrant_golden_years_to",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Maintain Weight",
    "Age Group": "70+",
    Gender: "Male",
    Question: "app.profunnel.motivation.question_screen.question.heading.maintain_weight",
    "Emoji 1": "🚴",
    "Option 1": "app.profunnel.motivation.question_screen.option.performance_fitness.stay_fit_agile",
    "Emoji 2": "🧘",
    "Option 2": "app.profunnel.motivation.question_screen.option.health_wellness.improve_overall_wellbeing",
    "Emoji 3": "⚡",
    "Option 3": "app.profunnel.motivation.question_screen.option.energy_vitality.energy_levels_high_to",
    "Emoji 4": "🌟",
    "Option 4": "app.profunnel.motivation.question_screen.option.energy_vitality.confident_vibrant_golden_years_to",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Something Else",
    "Age Group": "16-29",
    Gender: "Female",
    Question: "app.profunnel.motivation.question_screen.question.heading.something_else",
    "Emoji 1": "🧘",
    "Option 1": "app.profunnel.motivation.question_screen.option.health_wellness.improving_overall_wellbeing",
    "Emoji 2": "🪞",
    "Option 2": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feeling_proud_mirror_to",
    "Emoji 3": "⚡",
    "Option 3": "app.profunnel.motivation.question_screen.option.energy_vitality.more_energy_overall",
    "Emoji 4": "🦸",
    "Option 4": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.boosting_confidence_to",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Something Else",
    "Age Group": "16-29",
    Gender: "Male",
    Question: "app.profunnel.motivation.question_screen.question.heading.something_else",
    "Emoji 1": "🧘",
    "Option 1": "app.profunnel.motivation.question_screen.option.health_wellness.improving_overall_wellbeing",
    "Emoji 2": "🪞",
    "Option 2": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feeling_proud_mirror_to",
    "Emoji 3": "⚡",
    "Option 3": "app.profunnel.motivation.question_screen.option.energy_vitality.more_energy_overall",
    "Emoji 4": "🏃",
    "Option 4": "app.profunnel.motivation.question_screen.option.performance_fitness.being_more_athletic",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Something Else",
    "Age Group": "30-49",
    Gender: "Female",
    Question: "app.profunnel.motivation.question_screen.question.heading.something_else",
    "Emoji 1": "🧘",
    "Option 1": "app.profunnel.motivation.question_screen.option.health_wellness.improving_overall_wellbeing",
    "Emoji 2": "🪞",
    "Option 2": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feeling_proud_mirror_to",
    "Emoji 3": "⚡",
    "Option 3": "app.profunnel.motivation.question_screen.option.energy_vitality.energy_keep_up_kids",
    "Emoji 4": "🏆",
    "Option 4": "app.profunnel.motivation.question_screen.option.social_family.set_example_family_to",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Something Else",
    "Age Group": "30-49",
    Gender: "Male",
    Question: "app.profunnel.motivation.question_screen.question.heading.something_else",
    "Emoji 1": "🧘",
    "Option 1": "app.profunnel.motivation.question_screen.option.health_wellness.improving_overall_wellbeing",
    "Emoji 2": "🪞",
    "Option 2": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feeling_proud_mirror_to",
    "Emoji 3": "👨‍👩‍👧‍👦",
    "Option 3": "app.profunnel.motivation.question_screen.option.social_family.active_engaged_family",
    "Emoji 4": "😌",
    "Option 4": "app.profunnel.motivation.question_screen.option.health_wellness.manage_stress_levels",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Something Else",
    "Age Group": "50-69",
    Gender: "Female",
    Question: "app.profunnel.motivation.question_screen.question.heading.something_else",
    "Emoji 1": "🧘",
    "Option 1": "app.profunnel.motivation.question_screen.option.health_wellness.improving_overall_wellbeing",
    "Emoji 2": "🪞",
    "Option 2": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feeling_proud_mirror_to",
    "Emoji 3": "⚡",
    "Option 3": "app.profunnel.motivation.question_screen.option.energy_vitality.more_energy_day",
    "Emoji 4": "🏆",
    "Option 4": "app.profunnel.motivation.question_screen.option.social_family.example_family_grandkids",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Something Else",
    "Age Group": "50-69",
    Gender: "Male",
    Question: "app.profunnel.motivation.question_screen.question.heading.something_else",
    "Emoji 1": "🧘",
    "Option 1": "app.profunnel.motivation.question_screen.option.health_wellness.improving_overall_wellbeing",
    "Emoji 2": "🪞",
    "Option 2": "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feeling_proud_mirror_to",
    "Emoji 3": "⚡",
    "Option 3": "app.profunnel.motivation.question_screen.option.energy_vitality.more_energy_day",
    "Emoji 4": "🩺",
    "Option 4": "app.profunnel.motivation.question_screen.option.health_wellness.prevent_diseases",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Something Else",
    "Age Group": "70+",
    Gender: "Female",
    Question: "app.profunnel.motivation.question_screen.question.heading.something_else",
    "Emoji 1": "🚴",
    "Option 1": "app.profunnel.motivation.question_screen.option.performance_fitness.be_fit_agile",
    "Emoji 2": "🧘",
    "Option 2": "app.profunnel.motivation.question_screen.option.health_wellness.improving_overall_wellbeing",
    "Emoji 3": "⚡",
    "Option 3": "app.profunnel.motivation.question_screen.option.energy_vitality.energy_levels_high_to",
    "Emoji 4": "🌟",
    "Option 4": "app.profunnel.motivation.question_screen.option.energy_vitality.confident_vibrant_golden_years_to",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  },
  {
    Goal: "Something Else",
    "Age Group": "70+",
    Gender: "Male",
    Question: "app.profunnel.motivation.question_screen.question.heading.something_else",
    "Emoji 1": "🚴",
    "Option 1": "app.profunnel.motivation.question_screen.option.performance_fitness.be_fit_agile",
    "Emoji 2": "🧘",
    "Option 2": "app.profunnel.motivation.question_screen.option.health_wellness.improving_overall_wellbeing",
    "Emoji 3": "⚡",
    "Option 3": "app.profunnel.motivation.question_screen.option.energy_vitality.energy_levels_high_to",
    "Emoji 4": "🌟",
    "Option 4": "app.profunnel.motivation.question_screen.option.energy_vitality.confident_vibrant_golden_years_to",
    "Emoji 5": "💬",
    "Option 5": "app.profunnel.motivation.question_screen.option.something_else"
  }
];
