<script lang="ts" setup>
import Text from "~/components/atoms/Text.vue";

const { assetBaseUrl } = useRuntimeConfig().public;

const funnelStore = useFunnelStore();
const { locale } = useNuxtApp().$i18n;

const isKnetCalculatorFunnel = computed(() => {
  return funnelStore.variant === "knet-calculator";
});

const knetLogo = computed(() => {
  const availableLocales = ["de", "es", "fr", "it", "pt"];
  const logoLocale = availableLocales.includes(locale.value) ? locale.value : "en";

  return `/app/images/knet/logo-${logoLocale}.svg`;
});
</script>

<template>
  <header>
    <nav class="relative z-10 bg-white py-2 shadow-sm">
      <div class="container flex items-center justify-between px-bs-container">
        <img
          :src="assetBaseUrl + '/frontend/images/yazio-logo.svg'"
          class="h-[4.5rem] w-40 py-3"
          type="image/svg+xml"
          alt="YAZIO Logo"
        />
        <slot />
        <div v-if="isKnetCalculatorFunnel" class="flex flex-col items-end">
          <Text translation-key="app.profunnel.header.in_collaboration_with" color="gray" size="xs" bold></Text>
          <img :src="knetLogo" class="h-5 sm:h-6" alt="" />
        </div>
      </div>
    </nav>
  </header>
</template>
