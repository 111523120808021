export function useExpiringLocalStorage(expirationInSeconds: number) {
  const dayjs = useDayjs();
  const localStorage = piniaPluginPersistedstate.localStorage();

  function setItem(key: string, value: string): void {
    const expiry = dayjs().add(expirationInSeconds, "seconds").format("YYYY-MM-DD HH:mm:ss");
    const item = {
      expiry,
      value,
    };
    return localStorage.setItem(key, JSON.stringify(item));
  }

  function getItem(key: string): string | null {
    const itemStr = localStorage.localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    if (dayjs().isAfter(item.expiry)) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }

  return {
    setItem,
    getItem,
  };
}
