<script lang="ts" setup>
import { computed } from "vue";
import AppBackgroundImage from "~/components/app/AppBackgroundImage.vue";
import OnboardingStickyButtonWrapper from "~/components/onboarding/OnboardingStickyButtonWrapper.vue";
import type { InfoListScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";
import { getOnboardingConditions } from "~/utils/onboardingConditions";

interface Props {
  screen: InfoListScreen;
  registrationParams: RegistrationParams;
}

const props = defineProps<Props>();
const { getOrGetConditionalValue, getConditionalValue } = getOnboardingConditions(props);
const { t } = useNuxtApp().$i18n;

interface Emits {
  (
    e: "next",
    value: {
      params: Partial<RegistrationParams>;
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();

function next() {
  emit("next", {
    params: {},
    nextScreenId: getOrGetConditionalValue(props.screen.nextStep),
  });
}

const imageUrl = computed(() => {
  return getConditionalValue(props.screen.imageUrl);
});

const title = computed(() => {
  if (!props.screen.titleTranslationKey) {
    return "";
  }

  return t(getOrGetConditionalValue(props.screen.titleTranslationKey));
});

const infoList = computed(() => {
  return props.screen.infoList.filter((option) => {
    return getOrGetConditionalValue(option.visible);
  });
});
</script>

<template>
  <OnboardingStickyButtonWrapper :button-text="$t(screen.nextButtonTranslationKey)" @button-click="next">
    <AppBackgroundImage :src="imageUrl" :size="screen.imageSize" />
    <div class="container flex justify-center px-bs-container">
      <div class="py-5 lg:w-7/12">
        <h1>
          {{ title }}
        </h1>
        <div class="rounded-xl bg-white text-yz-gray-800 shadow-sm">
          <div class="flex flex-col content-center gap-4 p-4">
            <div v-for="(option, key) in infoList" :key="key" class="flex gap-4">
              <div>
                <span>{{ option.emoji }}</span>
              </div>
              <div v-if="option.translationKey">{{ $t(option.translationKey) }}</div>
              <div v-if="option.titleTranslationKey">{{ $t(option.titleTranslationKey) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </OnboardingStickyButtonWrapper>
</template>
