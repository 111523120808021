import { defineStore } from "pinia";
import { DaysToSeconds } from "@ilihub/time";

export const useFeatureFlagStore = defineStore(
  "featureFlags",
  () => {
    const featuresForcedValues = ref<{ [key: string]: boolean }>({});

    return {
      featuresForcedValues,
    };
  },
  {
    persist: {
      pick: ["featuresForcedValues"],
      storage: piniaPluginPersistedstate.cookies({
        maxAge: DaysToSeconds(1),
      }),
    },
  },
);
