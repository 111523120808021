<script lang="ts" setup>
import AppBackgroundImage from "~/components/app/AppBackgroundImage.vue";
import OnboardingStickyButtonWrapper from "~/components/onboarding/OnboardingStickyButtonWrapper.vue";
import type { AffirmationScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";
import { getOnboardingConditions } from "~/utils/onboardingConditions";

interface Props {
  screen: AffirmationScreen;
  registrationParams: RegistrationParams;
  hasWideWrapper: boolean;
}

const props = defineProps<Props>();
const { getOrGetConditionalValue } = getOnboardingConditions(props);
const { t } = useNuxtApp().$i18n;

interface Emits {
  (
    e: "next",
    value: {
      params: Partial<RegistrationParams>;
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();

function next() {
  emit("next", {
    params: {},
    nextScreenId: getOrGetConditionalValue(props.screen.nextStep),
  });
}

const imageUrl = computed(() => {
  return getOrGetConditionalValue(props.screen.imageUrl);
});

const title = computed(() => {
  if (!props.screen.titleTranslationKey) {
    return "";
  }

  return t(getOrGetConditionalValue(props.screen.titleTranslationKey));
});

const caption = computed(() => {
  if (!props.screen.captionTranslationKey) {
    return "";
  }

  return t(getOrGetConditionalValue(props.screen.captionTranslationKey));
});
</script>

<template>
  <OnboardingStickyButtonWrapper
    :button-disabled="false"
    :button-text="$t(screen.nextButtonTranslationKey)"
    :has-wide-wrapper="props.hasWideWrapper"
    @button-click="next"
  >
    <AppBackgroundImage :src="imageUrl" :size="screen.imageSize" :alignment="screen.imageAlignment" />
    <div class="container flex justify-center px-bs-container">
      <div class="lg:w-7/12">
        <h1>
          {{ title }}
        </h1>
        <div class="mb-5">{{ caption }}</div>
      </div>
    </div>
  </OnboardingStickyButtonWrapper>
</template>
