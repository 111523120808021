import { GrowthBook, configureCache } from "@growthbook/growthbook";
import { defineNuxtPlugin, useOnboardingStore, useRuntimeConfig } from "#imports";
import type { I18n } from "~/types/i18n";
import { useFeatureFlagStore } from "~/stores/useFeatureFlagStore";

const featureKeys = [
  "personalized_motivation_screen",
  "express_checkout",
  "paywall_new_color",
  "partner_banner_italic"
] as const;

interface FeatureFlagOptions {
  silent?: boolean; // dont trigger the trackingCallback
  ignoreStore?: boolean; // give the actual result from growthbook and not the store value
}

export type GrowthBookFeatureFlags = Record<
  (typeof featureKeys)[number],
  {
    isOn: (options?: FeatureFlagOptions) => ComputedRef<boolean>;
    isOff: (options?: FeatureFlagOptions) => ComputedRef<boolean>;
  }
>;

export default defineNuxtPlugin(async (nuxt) => {
  // mock the growthbook plugin for e2e tests
  if (useRequestHeaders(["user-agent"])["user-agent"] === "playwright-test" || navigator?.webdriver) {
    return {
      provide: {
        featureFlags: new Proxy(
          {},
          {
            get() {
              return {
                isOn: () => computed(() => false),
                isOff: () => computed(() => true),
              };
            },
          },
        ),
      },
    };
  }

  const {
    public: { growthBookApiHost, growthBookClientKey, growthBookEnableDevMode, growthBookEncryptionKey },
  } = useRuntimeConfig();

  const i18n = nuxt.$i18n as I18n;

  const { $pinia } = useNuxtApp();
  const userStore = useUserStore($pinia);
  const onboardingStore = useOnboardingStore($pinia);
  const biTrackingStore = useBiTrackingStore($pinia);
  const featureFlagStore = useFeatureFlagStore();

  const { trackGeneric } = useBiTrackingClient();

  configureCache({
    disableCache: true,
  });

  const growthbook = new GrowthBook({
    apiHost: growthBookApiHost,
    clientKey: growthBookClientKey,
    enableDevMode: growthBookEnableDevMode,
    decryptionKey: growthBookEncryptionKey,
    trackingCallback: async (experiment, result) => {
      if (nuxt.ssrContext) {
        // don't run the experiement tracking on the server due to event duplication
        return;
      }

      await trackGeneric({
        name: "experiment.assigned",
        experimentId: experiment.key,
        variationId: result.key,
      });
    },
  });

  async function setAttributes() {
    const attributes = {
      sex: onboardingStore.registrationParams.sex || undefined,
      goal: onboardingStore.registrationParams.goal || undefined,
      pro: !!(userStore.user && userStore.user.premium_type),
      country: onboardingStore.registrationParams.country || undefined,
      language: i18n.locale.value || undefined,
      session_id: biTrackingStore.getSessionId() || undefined,
      user_uuid: onboardingStore.registrationParams.userUuid || undefined,
      skipped_onboarding: onboardingStore.skippedOnboarding || undefined,
    };
    await growthbook.setAttributes(attributes);
  }

  await setAttributes();

  // Wait for features to be available
  await growthbook.init({ timeout: 2000 });

  // second growthbook instance to allow silent feature reading
  const _growthbook = new GrowthBook({
    attributes: growthbook.getAttributes(),
    features: growthbook.getFeatures(),
  });

  if (!nuxt.ssrContext) {
    watchEffect(async () => {
      await setAttributes();
      await _growthbook.setAttributes(growthbook.getAttributes());
    });
  }

  const featureFlags = featureKeys.reduce((acc, key) => {
    const isOn = (options?: FeatureFlagOptions) =>
      computed<boolean>(() => {
        const silentResult = _growthbook.isOn(key);
        const storeResult = featureFlagStore.featuresForcedValues?.[key];

        if (typeof storeResult === "boolean") {
          if (storeResult !== silentResult) {
            if (!options?.ignoreStore) {
              return storeResult;
            }
          }
        }
        if (options?.silent) {
          return silentResult;
        }
        return growthbook.isOn(key);
      });

    const isOff = (options?: FeatureFlagOptions) =>
      computed<boolean>(() => {
        return !isOn(options).value;
      });

    acc[key] = {
      isOn,
      isOff,
    };
    return acc;
  }, {} as GrowthBookFeatureFlags);

  return {
    provide: {
      featureFlags,
    },
  };
});
