import type { LocationQuery } from "vue-router";
import type { z } from "zod";

/**
 * Flattens the query parameters object to a simple Record<string, string>, removing null values.
 * When multiple values are present for a key, the last one is kept.
 */
export function flattenQueryParameters(query: LocationQuery): Record<string, string> {
  return Object.entries(query).reduce(
    (acc, [key, value]) => {
      const lastEntry = Array.isArray(value) ? value[value.length - 1] : value;

      if (lastEntry !== null) {
        acc[key] = lastEntry;
      }

      return acc;
    },
    {} as Record<string, string>,
  );
}

export interface QueryParameters {
  has<S extends string>(
    k: S,
  ): this is (string extends S ? object : { get(k: S): string; getAndRemove(k: S): string }) & this;
  has(key: string): boolean;
  hasAll<S extends string>(
    keys: S[],
  ): this is (string extends S ? object : { get(k: S): string; getAndRemove(k: S): string }) & this;
  hasAll(keys: string[]): boolean;
  get(key: string): string | undefined;
  getAndRemove(key: string): string | undefined;
  parseAndRemove<S extends z.ZodTypeAny>(key: string, schema: S): z.infer<S> | undefined;
  parse<S extends z.ZodTypeAny>(key: string, schema: S): z.infer<S> | undefined;
  remove(key: string): void;
  hasParameters(): boolean;
  getAndRemoveAll(keys: string[]): string | undefined;
  getAll(): Record<string, string> | undefined;
  haveParametersChanged(): boolean;
  getRemainingParameters(): Record<string, string>;
  sessionShouldReset(): boolean;
  isNewFlow(): boolean;
}

export function createQueryParameters(locationQuery: LocationQuery): QueryParameters {
  const params = flattenQueryParameters(locationQuery);
  const initialParams = new Map(Object.entries(params));
  const remainingParams = new Map(Object.entries(params));

  return {
    // @ts-ignore
    has: (key: string) => initialParams.has(key),
    // @ts-ignore
    hasAll: (keys: string[]) => keys.every((key) => initialParams.has(key)),
    get: (key: string) => initialParams.get(key),
    parse: <S extends z.ZodTypeAny>(key: string, schema: S): z.infer<S> | undefined => {
      const value = initialParams.get(key);
      if (value !== undefined) {
        const parsedValue = schema.safeParse(value);
        return parsedValue.success ? parsedValue.data : undefined;
      }
    },
    remove: (key: string) => remainingParams.delete(key),
    getAndRemove: (key: string) => {
      const value = initialParams.get(key);
      remainingParams.delete(key);
      return value;
    },
    parseAndRemove: <S extends z.ZodTypeAny>(key: string, schema: S): z.infer<S> | undefined => {
      const value = initialParams.get(key);
      remainingParams.delete(key);
      if (value !== undefined) {
        const parsedValue = schema.safeParse(value);
        return parsedValue.success ? parsedValue.data : undefined;
      }
    },
    getAndRemoveAll: (keys: string[]) => {
      return keys
        .map((key) => {
          const value = initialParams.get(key);
          remainingParams.delete(key);
          return value;
        })
        .find((value) => value !== undefined);
    },
    getAll: () => {
      return Object.fromEntries(initialParams);
    },
    hasParameters: () => initialParams.size > 0,
    haveParametersChanged: () => initialParams.size !== remainingParams.size,
    getRemainingParameters: () => Object.fromEntries(remainingParams),
    sessionShouldReset: () => initialParams.has("utm_source"),
    isNewFlow: () => initialParams.has("flowid"),
  };
}
