<script setup lang="ts">
import { useFeatureFlags } from "~/composables/useFeatureFlags";

const { t } = useNuxtApp().$i18n;

type Props = {
  discountOverride?: number;
};

const props = withDefaults(defineProps<Props>(), {
  discountOverride: 0,
});

const discountStore = useDiscountStore();
const partnerContent = await discountStore.useAsyncPartnerContent();
const discount = await discountStore.useAsyncDiscount();

const discountNumber = computed<number>(() => {
  if (props.discountOverride > 0) {
    return props.discountOverride;
  }

  return discount.data.value ?? 0;
});

const exclusiveLabel = computed(() => {
  return partnerContent.data.value
    ? t("text.webfunnel.partnership.firstpage.exclusive_offer", [partnerContent.data.value.name])
    : t("app.profunnel.paywall.plans.bar.no_trial_discount");
});

const discountLabel = computed(() =>
  t("text.webfunnel.partnership.firstpage.discount_forever", [discountNumber.value]),
);

const image = computed(() => {
  if (partnerContent.status.value !== "pending" && partnerContent.data.value?.image_url) {
    return `${partnerContent.data.value.image_url}?width=400`;
  }

  return "https://assets.yazio.com/frontend/images/pro-signup/discount-percentage-badge.svg";
});

const featureFlags = useFeatureFlags();
const showItalicDiscountText = computed(() => featureFlags.partner_banner_italic.isOn().value);
</script>

<template>
  <div
    v-if="discountNumber !== 0 || (partnerContent.data.value && discountNumber !== 0)"
    class="rounded-xl bg-yz-gray-100 text-yz-gray-800 shadow-sm"
    :class="{ 'animate-pulse': partnerContent.status.value === 'pending' }"
  >
    <div class="flex w-full items-center p-0 text-left" disabled="disabled">
      <div
        :style="`background-image: url(${image})`"
        class="size-[5.5rem] shrink-0 rounded-xl bg-cover bg-[center_top] bg-no-repeat"
        :class="{
          '!bg-[size:4rem]': !partnerContent.data.value?.image_url,
          '!bg-[center_center]': !partnerContent.data.value?.image_url,
        }"
      />

      <div class="flex flex-col p-4">
        <span v-html="exclusiveLabel"></span>
        <span
          class="text-xl font-medium leading-[30px] text-yz-rose-600"
          :class="{
            'italic': showItalicDiscountText,
          }"
          >{{ discountLabel }}</span
        >
      </div>
    </div>
  </div>
</template>
