<script lang="ts" setup>
import { match } from "ts-pattern";
import OnboardingStickyButtonWrapper from "~/components/onboarding/OnboardingStickyButtonWrapper.vue";
import type { BaseScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams, Sex } from "~/types/onboarding/registrationParams";
import AppImageStack from "~/components/app/AppImageStack.vue";
import CheckoutPlanReviews from "~/components/checkout/plan/reviews/CheckoutPlanReviews.vue";
import { getOnboardingConditions } from "~/utils/onboardingConditions";

interface Props {
  screen: BaseScreen;
  registrationParams: RegistrationParams;
}

const props = defineProps<Props>();
const { getOrGetConditionalValue } = getOnboardingConditions(props);

interface Emits {
  (
    e: "next",
    value: {
      params: Partial<RegistrationParams>;
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();

const { t } = useNuxtApp().$i18n;

const sex = computed<Sex>(() => props.registrationParams.sex || "male");

const images = computed<string[]>(() =>
  match(sex.value)
    .with("male", () => [
      "process/plain/app/success-stories/onboarding_success_story_male_1.png",
      "process/plain/app/success-stories/onboarding_success_story_male_2.png",
      "process/plain/app/success-stories/onboarding_success_story_male_3.png",
    ])
    .with("female", () => [
      "process/plain/app/success-stories/onboarding_success_story_1.png",
      "process/plain/app/success-stories/onboarding_success_story_2.png",
      "process/plain/app/success-stories/onboarding_success_story_3.png",
    ])
    .exhaustive(),
);

const title = t("onboarding.encouraging_flow.personal_goal.reviews_affirmation.title");
const description = t("onboarding.encouraging_flow.personal_goal.reviews_affirmation.description");

function next() {
  emit("next", {
    params: {},
    nextScreenId: getOrGetConditionalValue(props.screen.nextStep),
  });
}
</script>

<template>
  <OnboardingStickyButtonWrapper :button-text="$t('system.general.button.i_cant_wait')" @button-click="next">
    <div class="absolute inset-x-0 top-0 z-[-1] h-[40vh] w-full bg-[url('~/assets/images/background_shape_10.svg')]" />
    <div class="container px-bs-container pt-4">
      <AppImageStack
        :images="images"
        class="mx-auto block"
        :alt="title"
        :src-image-width="800"
        :stack-max-width="540"
      />
      <div class="mx-auto flex flex-wrap lg:w-7/12">
        <h1>
          {{ title }}
        </h1>
        <div class="mb-5">{{ description }}</div>
        <CheckoutPlanReviews />
      </div>
    </div>
  </OnboardingStickyButtonWrapper>
</template>
