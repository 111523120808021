import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);
export function getAgeFromBirthdate(birthdate: string) {
  return dayjs(dayjs()).diff(birthdate, "year");
}

export function ageToAgeRange(age: number): string {
  if (age < 30) {
    return "16-29";
  } else if (age >= 30 && age < 50) {
    return "30-49";
  } else if (age >= 50 && age < 70) {
    return "50-69";
  } else {
    return "70+";
  }
}
