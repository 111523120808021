import { createQueryParameters } from "~/utils/queryParams";

export default defineNuxtRouteMiddleware(async (to) => {
  const { $pinia } = useNuxtApp();

  // make sure every store is imported at least once on server side
  // there is a bug, that stores are not available on the client side if they are not imported on the server side
  const authStore = useAuthStore($pinia);
  const userStore = useUserStore($pinia);
  const tokenUserStore = useTokenUserStore($pinia);
  const onboardingStore = useOnboardingStore($pinia);
  const awinStore = useAwinStore($pinia);
  const iterableStore = useIterableStore($pinia);
  const funnelStore = useFunnelStore($pinia);
  const biTrackingStore = useBiTrackingStore($pinia);
  const checkoutStore = useCheckoutStore($pinia);
  const discountStore = useDiscountStore($pinia);
  const calculatorFunnelStore = useCalculatorFunnelStore($pinia);
  const metaTrackingStore = useMetaTrackingStore($pinia);

  const queryParameters = createQueryParameters(flattenQueryParameters(to.query));

  if (!queryParameters.hasParameters() || queryParameters.get("yzQueryHandled") === "true") {
    return;
  }

  onboardingStore.handleQueryParameters(queryParameters);
  onboardingStore.$persist();

  discountStore.handleQueryParameters(queryParameters);
  discountStore.$persist();

  awinStore.handleQueryParameters(queryParameters);
  awinStore.$persist();

  iterableStore.handleQueryParameters(queryParameters);
  iterableStore.$persist();

  funnelStore.handleQueryParameters(queryParameters);
  funnelStore.$persist();

  checkoutStore.handleQueryParameters(queryParameters);
  checkoutStore.$persist();

  biTrackingStore.handleQueryParameters(queryParameters);
  biTrackingStore.$persist();

  userStore.handleQueryParameters(queryParameters);
  userStore.$persist();

  authStore.handleQueryParameters(queryParameters);
  authStore.$persist();

  tokenUserStore.handleQueryParameters(queryParameters, to.path);
  tokenUserStore.$persist();

  calculatorFunnelStore.handleQueryParameters(queryParameters);
  calculatorFunnelStore.$persist();

  metaTrackingStore.handleQueryParameters(queryParameters);
  metaTrackingStore.$persist();

  await useLocale().handleQueryParameters(queryParameters);

  if (queryParameters.haveParametersChanged()) {
    const query = queryParameters.getRemainingParameters();

    await navigateTo(
      { path: `${useRuntimeConfig().app.baseURL}${to.path}`, query: { ...query, yzQueryHandled: "true" } },
      { external: true },
    );
  }
});
